import React from 'react';

const Blogs = () => {
	return (
		<div>
			<div class="container">
				<div className="experienceHeader"><b>Blogs</b></div>
				<div className="text-xl pb-20 text-dark-grey">Check back later</div>
			</div>
		</div>
	);
};

export default Blogs;
